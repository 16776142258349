import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BusEvent, BusService} from '../../../message-bus/bus.service';
import {NotificationService} from '../notification-service/notification.service';
import {CustomerTag} from '../../models/customer-tag.class';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomerTagService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient, private bus: BusService, private readonly notificationService: NotificationService, private readonly router: Router) {
        this.bus.on('CUSTOMER_TAGS.EDIT', (e: BusEvent) => {
            void this.router.navigate(['/settings/customer-tags', e.details.customerTagId]);
        });

        this.bus.on('CUSTOMER_TAGS.DELETE', (e: BusEvent) => {
            this.deleteCustomerTag(e.details.customerTagId).subscribe({
                next: () => {
                    this.notificationService.showTranslatedNotification('success', 'customer_tag', 'deleted', {
                        label: e.details.customerTagName
                    });

                    this.bus.emit({
                        id: 'LIST_PAGE.REFRESH'
                    });
                }
            });
        });
    }

    getCustomerTags(params?: HttpParams): Observable<HttpResponse<CustomerTag[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;
        return this.http.get<CustomerTag[]>(this.baseUrl + `/v1.0/customer/tag`, {...config, observe: 'response'});
    }

    public getCustomerTag(customerTagId: string): Observable<CustomerTag> {
        return this.http.get<CustomerTag>(`${environment.CM_API_URL}/seatedapi/v1.0/customer/tag/${customerTagId}?customerTag[analytics]`, {
            withCredentials: true
        }).pipe(
            map((new CustomerTag()).mapModel)
        );
    }

    public createCustomerTag(customerTag: CustomerTag): Observable<CustomerTag> {
        return this.http.post<CustomerTag>(`${environment.CM_API_URL}/seatedapi/v1.0/customer/tag/`, {
            name: customerTag.name,
        }, {
            withCredentials: true
        }).pipe(
            map((new CustomerTag()).mapModel)
        );
    }

    public updateCustomerTag(customerTag: CustomerTag): Observable<CustomerTag> {
        return this.http.patch<CustomerTag>(`${environment.CM_API_URL}/seatedapi/v1.0/customer/tag/${customerTag.id}`, {
            name: customerTag.name,
        }, {
            withCredentials: true
        }).pipe(
            map((new CustomerTag()).mapModel)
        );
    }

    private deleteCustomerTag(customerTagId: string): Observable<void> {
        return this.http.delete<void>(`${environment.CM_API_URL}/seatedapi/v1.0/customer/tag/${customerTagId}`, {
            withCredentials: true
        });
    }

    postCustomerTagImport(customerTagId: string, isRemoveCustomerTags: boolean, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('name', file.name);
        formData.append('customerTagId', customerTagId);

        if (isRemoveCustomerTags) {
            formData.append('isRemoveCustomerTags', 'true');
        }

        return this.http.post(this.baseUrl + `/v1.0/customer/tag/import`, formData, this.config);
    }
}
