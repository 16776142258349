import {ModelBase} from './abstract/model-base';
import {TableRow} from './abstract/table-row';

export class CustomerTag extends ModelBase implements TableRow {
    public id: string;
    public name: string;
    public customerAmount: number;
    public isSelected = false;

    constructor() {
        super();
        super.setRoutes({
            post: 'v1.0/customer/tag',
            getMany: 'v1.0/customer/tag',
            get: 'v1.0/customer/tag/:id',
            patch: 'v1.0/customer/tag/:id',
            delete: 'v1.0/customer/tag/:id'
        });
    }

    mapModel(data: any) {
        const model = new CustomerTag();
        model.id = data.id;
        model.name = data.name;
        model.customerAmount = data.customerAmount ?? 0;

        return model;
    }
}
